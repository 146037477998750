import { useContext } from "react";
import Login from "../pages/Login";
import AuthContext from "../contexts/AuthContext";

const AuthService = ({children}: { children: React.ReactElement }) => {
    const auth = useContext(AuthContext);
    if (!auth?.user) {
       return <Login />;
    }
    return children;
}

export default AuthService;