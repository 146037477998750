import React from 'react';
import User from '../interfaces/User';

type AuthContextType = {
    user: User | undefined;
    setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export default AuthContext;