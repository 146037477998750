import { IDetectedBarcode, Scanner } from "@yudiel/react-qr-scanner";
import axios from "axios";
import { useContext, useState } from "react"
import AuthContext from "../contexts/AuthContext";
import Swal from "sweetalert2";

const Home = () => {
    const [isScannerEnabled, setScannerEnabled] = useState<boolean>(false);
    const [paused, setPaused] = useState<boolean>(false);
    const auth = useContext(AuthContext);

    const onScan = (scan: IDetectedBarcode[]) => {
        if(scan.length > 0) {
            setPaused(true);
            Swal.showLoading();
            axios.get(scan[0].rawValue, {
                headers: {
                    Authorization: 'Bearer ' + auth?.user?.access_token
                }
            }).then(res => {
                console.log(res);
                Swal.close();
                Swal.fire({icon: 'success', text: 'Zaznaczono obecność!', title: 'Sukces', confirmButtonColor: '#28a745', showConfirmButton: true}).then(e => {
                    if(e.isConfirmed) {
                        Swal.close();
                        setPaused(false);
                    }
                });
            }).catch(err => {
                Swal.close();
                if(err?.response?.status === 409) {
                    Swal.fire({icon: 'warning', text: 'Uczestnik jest już obecny', title: 'Uwaga', confirmButtonColor: '#ffeeba', showConfirmButton: true}).then(e => {
                        if(e.isConfirmed) {
                            Swal.close();
                            setPaused(false);
                        }
                    });
                } else if(err?.response?.status === 404 || err?.response?.status === 400) {
                    Swal.fire({icon: 'error', text: 'Błędny kod QR', title: 'Błąd', confirmButtonColor: 'red', showConfirmButton: true}).then(e => {
                        if(e.isConfirmed) {
                            Swal.close();
                            setPaused(false);
                        }
                    });
                } else {
                    let text = '';
                    if(err?.response?.status === 401) {
                        text = 'Wylogowano';
                    } else {
                        text = 'Nieznany błąd';
                    }
                    Swal.fire({icon: 'error', text, title: 'Błąd', confirmButtonColor: 'red', showConfirmButton: true}).then(e => {
                        if(e.isConfirmed) {
                            Swal.close();
                            setPaused(false);
                        }
                    });
                    auth?.setUser(undefined);
                }
            });
        }
    }

    return (
        <div className="flex flex-col justify-between h-[100svh]">
            <img src="bodie_logo.png" />
            {isScannerEnabled && 
                <Scanner paused={paused} onScan={onScan} styles={{finderBorder: 1}}
                allowMultiple={false} torch={true} />}
            <div className="flex flex-col justify-start">
                <button className="py-4 bg-[#28a745] text-white rounded-md" onClick={e => {
                    setScannerEnabled(!isScannerEnabled);
                }}>{isScannerEnabled ? "Przestań skanować" : "Skanuj"}</button>
                {/* isScannerEnabled &&
                    <button className="py-4 bg-[#007bff] text-white rounded-md" onClick={e => {
                        setTorchEnabled(!isTorchEnabled);
                    }}>{isTorchEnabled ? "Wyłącz" : "Włącz"} latarkę</button> */}
                <button className="py-4 bg-[#f8f9fa] rounded-md" onClick={e => {
                    auth?.setUser(undefined);
                }}>Wyloguj</button>
            </div>
        </div>
    );
}
export default Home;