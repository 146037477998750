import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';
import { useState } from 'react';
import AuthContext from './contexts/AuthContext';
import AuthService from './services/AuthService';
import User from './interfaces/User';

function App() {
    const [user, setUser] = useState<User | undefined>();

    return (
        <AuthContext.Provider value={{user, setUser}} >
            <BrowserRouter>
                <Routes>
                    <Route index element={<AuthService>
                            <Home />
                        </AuthService> } />
                    <Route path="/login" element={<Login />} />
                </Routes>
            </BrowserRouter>
        </AuthContext.Provider>
    );
}

export default App;
