import { SyntheticEvent, useContext, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import Input from "../components/Input";
import Password from "../components/Password";
import axios from "axios";
import UrlContext from "../contexts/UrlContext";
import Swal from "sweetalert2";

const Login = () => {
    const auth = useContext(AuthContext);
    const url = useContext(UrlContext);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');

    const signIn = async (e: SyntheticEvent)  => {
        Swal.showLoading();
        e.preventDefault();
        axios.post(url + '/login', {
            username,
            password
        }, {
            withCredentials: false,
        }).then(res => {
            const data = res.data;
            auth?.setUser({
                username: username,
                access_token: data.token
            });
        }).catch(err => {
            console.error(err);
            if(err?.response?.status === 401) {
                setPasswordError('Zły login lub hasło.');
            } else if(err.code === "ERR_NETWORK") {
                setPasswordError('Brak połączenia z serwerem.');
            } else {
                setPasswordError('Nieznany błąd.');
            }
            // alert(JSON.stringify(err));
        }).finally(() => {
            Swal.close();
        });
    }

    return (
        <div className="flex items-center justify-center" style={{height: '100svh'}}>
            <form className="flex flex-col justify-evenly w-[80%] gap-4">
                <img src="bodie_logo.png" alt="logo" />
                <div>
                    <Input data={username} name="username" updateData={setUsername} isRequired={true} disabled={false} readOnly={false} label="Login"/>
                    <Password data={password} name="password" updateData={setPassword} error={passwordError} isRequired={true} disabled={false} readOnly={false} label="Hasło"/>
                </div>
                <button className="mt-4 py-2 bg-[#28a745] text-white rounded-md" type="submit" onClick={signIn}>Zaloguj</button>
            </form>
        </div>
    );
}
export default Login;