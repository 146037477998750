const Input = ({name, label, data, updateData, isRequired = false, disabled = false, readOnly = false}: {
    name: string, label: string | undefined, data: string, updateData: React.Dispatch<React.SetStateAction<string>>,
    isRequired: boolean, disabled: boolean, readOnly: boolean}) => {
    const text = (label === undefined ? name : label);
    return (
        <>
            <div className="">
                <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900 text-left">{text.charAt(0).toUpperCase() + text.slice(1)}: </label>
                <div className="mt-2">
                    <input readOnly={readOnly} disabled={disabled} value={data || ''} required={isRequired} onChange={(event) => {
                    updateData(event.target.value);
                    }} type="text" name={name} id={name} autoComplete="given-name" className="ps-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-300 sm:text-sm sm:leading-6"/>
                </div>
            </div>
        </>
    );
}

export default Input;